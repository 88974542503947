import React, { useState, useEffect, useMemo, useCallback } from "react";
import "../css/overview.css";
import PuffLoader from "react-spinners/PuffLoader";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getParagraphsFrom8k502 } from "../redux/actions/CompanyAction";
import slugifyName from "../utils/slugifyName";
import {
  ETRADE_URL,
  toDollar,
  isFirstYearOfComp,
  MIN_YEAR,
} from "../utils/Constants/systemSetting";
import {
  getExecPosition,
  getExecPositionGroup,
  getExecPositionScore,
} from "../utils/ExecutiveData/getExecPosition";
import LineGraph from "../components/Graphs/LineGraph";
import dataFilter from "../utils/DataFilter/dataFilter";
import NotFound from "./notFound";
import CohortSection from "../components/ExecutiveOverview/CohortSection";
import PercentileTable from "../components/ExecutiveOverview/PercentileTable";
import MeasureScatterplotAndTable from "../components/ExecutiveOverview/MeasureScatterplotAndTable";
import {
  formatMeasure,
  payRatioCalculatedMessage,
} from "../utils/Constants/systemSetting";
import ExportPDFButton from "../components/ExportPDFButton";
import ExecutiveProfile from "../components/ExecutiveOverview/ExecutiveProfile";

export default function ExecutiveOverview(props) {
  const dispatch = useDispatch();

  const { companies, marketCap: marketCaps } = useSelector(
    (state) => state.CompaniesReducer
  );
  const { csuite: allExecutives } = useSelector(
    (state) => state.ExecutivesReducer
  );

  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("2020");
  const [ticker, setTicker] = useState("");
  const [executiveSlug, setExecutiveSlug] = useState("");
  const [companyInfo, setCompanyInfo] = useState({});
  const [executive, setExecutive] = useState();
  const [marketCap, setMarketCap] = useState();
  const [yearsWithData, setYearsWithData] = useState([]);
  const [payRatioIsCalculated, setPayRatioIsCalculated] = useState(false);

  const [lineGroup, setLineGroup] = useState({});

  const getDatapoint = useCallback(
    (datapoint) => {
      /* Allows for getting most recent year */
      if (
        companyInfo?.[datapoint]?.[year?.toString()]?.value ||
        companyInfo?.[datapoint]?.[year?.toString()]?.value === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: companyInfo?.[datapoint]?.[year?.toString()]?.value,
          calculated: companyInfo?.[datapoint]?.[year?.toString()]?.calculated
            ? true
            : false,
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [companyInfo, year]
  );

  const getMeasure = useCallback(
    (measureName) => {
      /* Allows for getting most recent year */
      if (
        companyInfo?.Measures?.[measureName]?.[year?.toString()] ||
        companyInfo?.Measures?.[measureName]?.[year?.toString()] === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: companyInfo?.Measures?.[measureName]?.[year?.toString()],
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [companyInfo, year]
  );

  const renderNetIncome = (netIncome) => {
    if (netIncome === "N/A") {
      return <span>N/A</span>;
    } else if (netIncome < 0) {
      return (
        <span className="text-danger">
          ({toDollar(Number(netIncome.toString().slice(1)))})
        </span>
      );
    } else {
      return <span className="text-success">{toDollar(netIncome)}</span>;
    }
  };

  const [executiveNotFound, setExecutiveNotFound] = useState(false);
  useEffect(() => {
    setTicker(props.match.params?.id);
    setExecutiveSlug(props.match.params?.executiveName);

    const urlParts = props.location.pathname.split("/");
    if (!urlParts.includes(executiveSlug)) {
      setLoading(true);
    }
    setCompanyInfo(companies[ticker]);

    const matchingCompany = marketCaps.find(
      (company) => company.Ticker === ticker
    );
    if (matchingCompany) {
      setMarketCap(matchingCompany.marketCap);
    }

    const matchingExecutive = allExecutives.find((executive) => {
      return (
        executive.Ticker === ticker &&
        slugifyName(executive.name) === executiveSlug
      );
    });

    if (matchingExecutive) {
      setExecutiveNotFound(false);
      setExecutive(matchingExecutive);
    } else {
      setExecutiveNotFound(true);
    }

    if (
      allExecutives.length &&
      Object.keys(companies).length > 7 &&
      companyInfo?.Ticker === ticker
    ) {
      dispatch(getParagraphsFrom8k502(ticker));
      setLoading(false);
    }
  }, [
    props,
    allExecutives,
    companies,
    marketCaps,
    companyInfo,
    executiveSlug,
    ticker,
    dispatch,
  ]);

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  useMemo(() => {
    const newYears = [];
    try {
      if (executive?.compensation) {
        for (const year of Object.keys(executive?.compensation)) {
          if (!newYears.includes(year)) {
            newYears.push(year);
          }
        }
      }
    } catch {
      newYears.push("2020");
    }
    setYearsWithData(newYears.sort((a, b) => parseInt(b) - parseInt(a)));
  }, [executive]);

  useEffect(() => {
    setYear(yearsWithData[0]);
  }, [yearsWithData, executive]);

  const yearsOptions = useMemo(() => {
    return yearsWithData.map((year, index) => (
      <option value={year} key={index}>
        {year}
      </option>
    ));
  }, [yearsWithData]);

  const [execPosition, setExecPosition] = useState([]);
  const [execPositionGroup, setExecPositionGroup] = useState([]);
  const [pageContainsNewComp, setPageContainsNewComp] = useState(false);
  useEffect(() => {
    if (executive) {
      const positions = getExecPosition(executive, year);
      setExecPosition(positions);
      const execPosGroups = [];
      for (const pos of positions) {
        execPosGroups.push(getExecPositionGroup(pos));
      }
      const chiefPositions = [];
      let bestScore = Infinity; //lowest is best
      let bestPos = "";
      for (const execPosGroup of execPosGroups) {
        if (execPosGroup === "N/A") continue;
        let currentScore = getExecPositionScore(execPosGroup);
        if (execPosGroup.formatted.includes("Chief ")) {
          chiefPositions.push(execPosGroup);
        }
        if (currentScore < bestScore) {
          bestScore = currentScore;
          bestPos = execPosGroup;
        }
      }
      // For all matching positions:
      // setExecPositionGroup(execPosGroup);
      // For just the "best" position(s):
      setExecPositionGroup(
        chiefPositions.length ? chiefPositions : bestPos ? [bestPos] : ["N/A"]
      );
    }
    setPageContainsNewComp(isFirstYearOfComp(executive, year));
  }, [executive, year]);

  const formatExecPositionGroup = (positionGroups) => {
    if (positionGroups.includes("N/A")) return ["N/A"];
    const formattedPositons = [];
    for (const positionGroup of positionGroups) {
      for (const [key, value] of Object.entries(positionGroup)) {
        if (key === "formatted") {
          formattedPositons.push(value);
        }
      }
    }
    return formattedPositons;
  };

  useEffect(() => {
    setPayRatioIsCalculated(getDatapoint("PayRatio")?.calculated);
  }, [getDatapoint, companyInfo, year]);

  useEffect(() => {
    const yearsArray = () => {
      const years = [];
      for (let year = new Date().getFullYear(); year >= MIN_YEAR; year--) {
        years.push(year);
      }
      return years;
    };
    const years = yearsArray();
    const newLineGroup = {};
    for (let y in years) {
      const newData = new dataFilter([executive], year, companies);
      newLineGroup[years[y]] = newData.data;
    }
    setLineGroup(newLineGroup);
  }, [year, executive, companies]);

  const [PDFprocessing, setPDFprocessing] = useState(false);
  const [captureCorrelationTableInPDF, setCaptureCorrelationTableInPDF] =
    useState(false);

  const elementsIDsForPDFExport = useMemo(() => {
    const elementsIDs = [
      ["executive-overview", "percentile-table"],
      ["cohort-selectors-and-table"],
      ["cohort-summary-section", "measure-correlation-graph"],
    ];
    if (captureCorrelationTableInPDF) {
      elementsIDs.push(["measure-correlation-table"]);
    }
    return elementsIDs;
  }, [captureCorrelationTableInPDF]);

  /* To change and reset width of component */
  // useEffect(() => {
  //   if (captureCorrelationTableInPDF) {
  //     if (PDFprocessing) {
  //       /* To change width of component (if doesn't fit on PDF page) */
  //       document.getElementById("measure-correlation-table").style.width =
  //         "80%";
  //     }
  //     if (!PDFprocessing) {
  //       /* Reset widths of components when done: */
  //       document.getElementById("measure-correlation-table").style.width =
  //         "100%";
  //     }
  //   }
  // }, [PDFprocessing, captureCorrelationTableInPDF]);

  return (
    <div
      className="overview container mb-3"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {loading ? (
        <center
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PuffLoader color={"var(--loader)"} loading={loading} size={100} />
        </center>
      ) : executiveNotFound ? (
        <NotFound />
      ) : (
        <div className="container" style={{ marginTop: "125px" }}>
          <div className="row" id="executive-overview">
            <div className="col-lg-6 mb-2 company-info">
              <h1 style={{ marginBottom: 0 }}>
                {pageContainsNewComp ? executive?.name + "*" : executive?.name}
              </h1>
              <h3>{execPosition.join(", ")}</h3>
              <h4 style={{ color: "var(--link-blue)" }}>
                <Link to={`/company/${executive?.Ticker}`}>
                  {executive?.company}
                </Link>
              </h4>
              <div>
                Year:{" "}
                {!PDFprocessing ? (
                  <select
                    name="year"
                    onChange={handleYearChange}
                    value={year}
                    style={{ textAlign: "center", height: 30 }}
                  >
                    {yearsOptions}
                  </select>
                ) : (
                  <span>{year}</span>
                )}
              </div>
              <div>
                Stock Ticker:{" "}
                <a
                  target="_blank"
                  href={`${ETRADE_URL}${executive?.Ticker}`}
                  rel="noreferrer"
                >
                  {executive?.Ticker}
                </a>
              </div>
              {executive?.AlternateTickers?.length ? (
                <div>
                  Alternate Ticker
                  {executive?.AlternateTickers?.length > 2 && "s"}:{" "}
                  <span>
                    {executive?.AlternateTickers.filter(
                      (ticker) => ticker !== executive?.Ticker
                    ).join(", ")}
                  </span>
                </div>
              ) : null}
              <div>
                Sector: <span>{companyInfo?.Sector || "Other"}</span>
              </div>
              <div>
                Industry: <span>{companyInfo?.Industry || "Other"}</span>
              </div>
              <div>
                {year} Assets:{" "}
                <span>
                  {getMeasure("Assets")?.value !== "N/A"
                    ? "$" + getMeasure("Assets")?.value?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                {year} Revenue:{"  "}
                <span>
                  {getDatapoint("Revenue")?.value !== "N/A"
                    ? "$" + getDatapoint("Revenue")?.value?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                {year} Profit (Loss):{" "}
                {renderNetIncome(getDatapoint("NetIncome")?.value)}
              </div>
              <div>
                {year} CEO Pay Ratio:{" "}
                {getDatapoint("PayRatio")?.value !== "N/A" ? (
                  <span>
                    {formatMeasure(getDatapoint("PayRatio").value, "ratio")} : 1
                    {payRatioIsCalculated && <sup> †</sup>}
                  </span>
                ) : (
                  <span>N/A</span>
                )}
              </div>
              <div>
                Current Market Cap:{" "}
                <span>
                  {marketCap
                    ? "$" + parseInt(marketCap).toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                Current Employee Count:{" "}
                <span>
                  {companyInfo?.Employees >= 0
                    ? companyInfo?.Employees?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                Location: <span>{companyInfo?.Address || "N/A"}</span>
              </div>
              <div>
                {pageContainsNewComp
                  ? `*${year} was this executive's first year of compensation.`
                  : ""}
              </div>
              <div style={{ marginTop: 6 }}>
                <span>
                  {payRatioIsCalculated &&
                    payRatioCalculatedMessage(companyInfo, year)}
                </span>
              </div>
              <div className="exportPDF" style={{ marginTop: 6 }}>
                <ExportPDFButton
                  setPDFprocessing={setPDFprocessing}
                  elementIDgroups={elementsIDsForPDFExport}
                />
              </div>
            </div>

            <div className="col-lg-6 mb-4 company-info text-center">
              <div>
                {formatExecPositionGroup(execPositionGroup) && (
                  <h3 style={{ marginLeft: 35 }}>
                    Position Group:{" "}
                    {formatExecPositionGroup(execPositionGroup).join(", ")}
                  </h3>
                )}
              </div>
              <div className="c-table">
                <div className="graphs" style={{ margin: 0, maxHeight: 500 }}>
                  <LineGraph
                    data={lineGroup}
                    currentYear={year}
                    executive={executive?.name}
                    PDFprocessing={PDFprocessing}
                  />
                </div>
              </div>
            </div>
          </div>
          <ExecutiveProfile
            companyInfo={companyInfo}
            execPosition={execPosition ? execPosition.join(", ") : "N/A"}
            executiveSlug={executiveSlug}
          />
          <PercentileTable
            executive={executive}
            execPositionGroup={execPositionGroup}
            year={year}
          />

          <hr />
          <CohortSection
            companyInfo={companyInfo}
            executive={executive}
            execPositionGroup={execPositionGroup}
            year={year}
            PDFprocessing={PDFprocessing}
          />

          <hr />
          <MeasureScatterplotAndTable
            executive={executive}
            companyInfo={companyInfo}
            execPositionGroup={execPositionGroup}
            year={year}
            PDFprocessing={PDFprocessing}
            setCaptureCorrelationTableInPDF={setCaptureCorrelationTableInPDF}
          />
        </div>
      )}
    </div>
  );
}
