import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  PieChart,
  Pie,
  Cell,
  Sector,
} from "recharts";
import { Table, Tooltip, Button } from "antd";
import { getAwards } from "../redux/actions/CompanyAction";
import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector";
import slugifyName from "../utils/slugifyName";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";

export default function IncentiveStructure({ companyInfo, PDFprocessing }) {
  const version = localStorage.getItem("version") || "1.0.0";
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);
  const { awards, companies } = useSelector((state) => state.CompanyReducer);

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortSelected, setCohortSelected] = useState("None");

  const [yearSelected, setYearSelected] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getAwards([ticker], token));
    }
  }, [companyInfo, dispatch]);

  const awardsDataLTI = useMemo(() => {
    return awards?.[companyInfo.Ticker]?.LTI &&
      awards?.[companyInfo.Ticker]?.LTI?.length
      ? awards?.[companyInfo.Ticker]?.LTI.map((item) => ({
          ...item,
          key: item.Metric + companyInfo.Company,
        }))
      : [];
  }, [awards, companyInfo]);

  const awardsDataSTI = useMemo(() => {
    return awards?.[companyInfo.Ticker]?.STI &&
      awards?.[companyInfo.Ticker]?.STI?.length
      ? awards?.[companyInfo.Ticker]?.STI.map((item) => ({
          ...item,
          key: item.Metric + companyInfo.Company,
        }))
      : [];
  }, [awards, companyInfo]);

  useMemo(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (cohortTableTickers.length) {
      dispatch(getAwards([ticker, ...cohortTableTickers], token));
    }
  }, [companyInfo, cohortTableTickers, dispatch]);

  const allAwardsDataAllCompanies = useMemo(() => {
    const allMetricsLTI = [];
    const allMetricsSTI = [];
    if (!awards) return { LTI: allMetricsLTI, STI: allMetricsSTI };

    Object.values(awards)
      .filter((company) => company?.LTI?.length > 1)
      .forEach((companyData) => {
        companyData?.LTI.forEach((metricDetails) => {
          allMetricsLTI.push({
            Ticker: companyData.Ticker,
            Company: companyData.CompanyName,
            key: metricDetails.Metric + companyData.Ticker,
            ...metricDetails,
          });
        });
        companyData?.STI.forEach((metricDetails) => {
          allMetricsSTI.push({
            Ticker: companyData.Ticker,
            Company: companyData.CompanyName,
            key: metricDetails.Metric + companyData.Ticker,
            ...metricDetails,
          });
        });
      });
    return { LTI: allMetricsLTI, STI: allMetricsSTI };
  }, [awards]);

  const awardsColumns = useMemo(
    () => [
      {
        title: "Ticker",
        dataIndex: "Ticker",
        key: "Ticker",
      },
      {
        title: "Company Name",
        dataIndex: "Company",
        key: "Company",
      },
      {
        title: "Metric",
        dataIndex: "Metric",
        key: "Metric",
      },
      {
        title: "Metric Details",
        dataIndex: "MetricDetails",
        key: "MetricDetails",
      },
      {
        title: "Metric Superscripts",
        dataIndex: "MetricSuperscripts",
        key: "MetricSuperscripts",
        responsive: [],
      },
      {
        title: "Weight",
        dataIndex: "Weight",
        key: "Weight",
      },
      {
        title: "Below Threshold Value",
        dataIndex: "BelowThresholdValue",
        key: "BelowThresholdValue",
      },
      {
        title: "Below Threshold Payout",
        dataIndex: "BelowThresholdPayout",
        key: "BelowThresholdPayout",
      },
      {
        title: "Threshold Value",
        dataIndex: "ThresholdValue",
        key: "ThresholdValue",
      },
      {
        title: "Threshold Payout",
        dataIndex: "ThresholdPayout",
        key: "ThresholdPayout",
      },
      {
        title: "Target Value",
        dataIndex: "TargetValue",
        key: "TargetValue",
      },
      {
        title: "Target Payout",
        dataIndex: "TargetPayout",
        key: "TargetPayout",
      },
      {
        title: "Maximum Value",
        dataIndex: "MaximumValue",
        key: "MaximumValue",
      },
      {
        title: "Maximum Payout",
        dataIndex: "MaximumPayout",
        key: "MaximumPayout",
      },
      {
        title: "Above Maximum Value",
        dataIndex: "AboveMaximumValue",
        key: "AboveMaximumValue",
      },
      {
        title: "Above Maximum Payout",
        dataIndex: "AboveMaximumPayout",
        key: "AboveMaximumPayout",
      },
      // {
      //   title: "Is Percentile",
      //   dataIndex: "isPercentile",
      //   key: "isPercentile",
      //   render: (text) => (text ? "Yes" : "No"),
      // },
      // {
      //   title: "Is Arbitrary",
      //   dataIndex: "isArbitrary",
      //   key: "isArbitrary",
      //   render: (text) => (text ? "Yes" : "No"),
      // },
      // {
      //   title: "Is Interpolated",
      //   dataIndex: "isInterpolated",
      //   key: "isInterpolated",
      //   render: (text) => (text ? "Yes" : "No"),
      // },
      {
        title: "Citation",
        dataIndex: "citation",
        key: "citation",
        responsive: [],
      },
      // {
      //   title: "Explanation",
      //   dataIndex: "explanation",
      //   key: "explanation",
      // },
    ],
    []
  );

  const customSorter = (a, b, key) => {
    const valA = a[key];
    const valB = b[key];

    // If both values are numbers, compare numerically
    if (!isNaN(parseFloat(valA)) && !isNaN(parseFloat(valB))) {
      return parseFloat(valA) - parseFloat(valB);
    }

    // Otherwise, compare as strings
    return String(valA).localeCompare(String(valB));
  };

  const incentivesColumns = useCallback(
    (incentivesData) => {
      if (!incentivesData) return [];

      const columns = JSON.parse(JSON.stringify(awardsColumns))
        .filter((item) => {
          return incentivesData.some(
            (award) =>
              Object.keys(award).includes(item.dataIndex) &&
              award[item.dataIndex] !== ""
          );
        })
        .map((item) => {
          item.sorter = (a, b) => customSorter(a, b, item.dataIndex);
          if (item.dataIndex === "MetricDetails") {
            item.render = (text) =>
              text.split(". ").length > 1 && text.length > 200
                ? text.split(". ")[0] + ". ..."
                : text;
            item.width = 200;
          }
          return item;
        });

      return columns;
    },
    [awardsColumns]
  );

  const formatDataForCSVexport = useCallback(
    (awardsData) => {
      const compensationDecisionOutputData = [];
      if (awardsData?.length) {
        compensationDecisionOutputData.push(
          ...awardsData?.map((item) => {
            return { ...item };
          })
        );
      }
      const compensationDecisionHeaders = [];
      incentivesColumns(awardsData).forEach((item) => {
        if (!compensationDecisionHeaders.includes(item.dataIndex)) {
          compensationDecisionHeaders.push(item.dataIndex);
        }
      });
      const formattedBoardPayData = compensationDecisionOutputData.map(
        (item) => {
          const formattedItem = {};
          incentivesColumns(awardsData).forEach((keyDetails, index) => {
            if (keyDetails?.dataIndex) {
              formattedItem[`"${keyDetails.title}"`] =
                item?.[keyDetails.dataIndex] &&
                (item?.[keyDetails?.dataIndex].length > 2 ||
                  item?.[keyDetails?.dataIndex] >= 0)
                  ? item[keyDetails?.dataIndex].toString()?.replaceAll(",", "")
                  : "";
            }
          });
          return formattedItem;
        }
      );
      return formattedBoardPayData;
    },
    [incentivesColumns]
  );

  const IncentivesTable = ({
    Title,
    Columns,
    Values,
    CompanyName,
    peerGroupSelected = "",
    size = "mid",
  }) => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <h2 style={{ textAlign: "center" }}>{Title}</h2>
        {Columns.length ? (
          <>
            {!PDFprocessing && (
              <div style={{ width: "100%" }}>
                <Button
                  style={{ marginBottom: 10 }}
                  onClick={() =>
                    exportToCSV(
                      formatDataForCSVexport(Values),
                      `${companyInfo?.Ticker} ${Title.split(" ")
                        .slice(0, 2)
                        .join(" ")} ${
                        new Date().toString().split("GMT-")[0]
                      }.csv`
                    )
                  }
                >
                  Export {Title.split(" ").slice(0, 2).join(" ")} as CSV
                </Button>
              </div>
            )}
            <Table
              dataSource={Values}
              columns={Columns}
              rowKey="key"
              pagination={{ hideOnSinglePage: true }}
              size={size}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <p
                      style={{
                        margin: 0,
                        textAlign: "left",
                      }}
                    >
                      <b>Metric Details:</b> {record.MetricDetails}{" "}
                      {record.citation ? (
                        <span> ({record.citation})</span>
                      ) : null}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        textAlign: "left",
                      }}
                    >
                      {record.MetricSuperscripts ? (
                        <span>
                          <b>Note:</b> {record.MetricSuperscripts}
                        </span>
                      ) : null}
                    </p>
                  </>
                ),
              }}
            />
          </>
        ) : (
          <p style={{ textAlign: "center" }}>
            No Short-Term Incentives found for {CompanyName}
            {peerGroupSelected ? `'s ${peerGroupSelected}` : ""}
          </p>
        )}
      </div>
    );
  };

  const COLORS = [
    "#59bd4b",
    "#4f5cc4",
    "#d29fd9",
    "#d95766",
    "#de8c59",
    "#ad7857",
    "#7f91a1",
    "#FFBB28",
    "#FF8042",
    "#0088FE",
    "#00C49F",
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Prevalence ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  const [activeIndex, setActiveIndex] = useState("");
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const peerGroupMetrics = useMemo(() => {
    const peerGroupMetrics = {};
    for (const [key, metrics] of Object.entries(allAwardsDataAllCompanies)) {
      const allMetricCounts = [];

      for (const metric of metrics) {
        if (
          allMetricCounts.some(
            (item) => slugifyName(item.label) === slugifyName(metric.Metric)
          )
        ) {
          allMetricCounts[
            allMetricCounts.indexOf(
              allMetricCounts.find(
                (item) => slugifyName(item.label) === slugifyName(metric.Metric)
              )
            )
          ].count += 1;

          allMetricCounts[
            allMetricCounts.indexOf(
              allMetricCounts.find(
                (item) => slugifyName(item.label) === slugifyName(metric.Metric)
              )
            )
          ].tickers.push(metric.Ticker);
        } else {
          allMetricCounts.push({
            label: metric.Metric,
            value: metric.Metric,
            key: slugifyName(metric.Metric),
            count: 1,
            tickers: [metric.Ticker],
            units: "",
          });
        }
      }
      peerGroupMetrics[key] = allMetricCounts.sort((a, b) => b.count - a.count);
    }
    return peerGroupMetrics;
  }, [allAwardsDataAllCompanies]);

  const filteredPeerGroupMeasures = useCallback(
    (key) => {
      if (!peerGroupMetrics?.[key]) return [];
      if (peerGroupMetrics?.[key]?.length < 40) return peerGroupMetrics?.[key];
      const filteredMeasures = peerGroupMetrics?.[key].filter(
        (item) => item.count > 1
      );
      const otherMeasures = peerGroupMetrics?.[key].filter(
        (item) => item.count <= 1
      );
      filteredMeasures.push({
        label: "Other",
        value: "Other",
        key: "Other",
        count: otherMeasures.length,
        units: "",
      });
      return filteredMeasures;
    },
    [peerGroupMetrics]
  );

  const peerGroupMeasuresColumns = [
    {
      title: "Measure",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Prevalence Count",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (text, record) => {
        return (
          <Tooltip
            title={
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <b>Appears in:</b>
                <span>
                  {record.tickers.map((tick) => (
                    <p>{tick}</p>
                  ))}
                </span>
              </div>
            }
          >
            <div>{text}</div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>Contact your account manager to access Award Structure data.</p>
          </h3>
        </div>
      ) : (
        <>
          {!incentivesColumns(awardsDataLTI).length &&
          !incentivesColumns(awardsDataSTI).length ? (
            <div
              className="company-calculator-section col-lg-9 executives p-0"
              style={{ textAlign: "center", marginTop: 30 }}
            >
              <h2>Incentive Stucture for {companyInfo?.Ticker} Coming Soon</h2>
            </div>
          ) : (
            <>
              <div className="company-calculator-section col-lg-9 executives p-0 ">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                    marginLeft: "20px",
                    width: "100%",
                  }}
                ></div>

                <IncentivesTable
                  Title={`Long-Term Incentives for ${companyInfo.Company}`}
                  Columns={incentivesColumns(awardsDataLTI)}
                  Values={awardsDataLTI}
                  CompanyName={companyInfo?.Company}
                />
              </div>

              <div className="company-calculator-section executives">
                <IncentivesTable
                  Title={`Short-Term Incentives for ${companyInfo.Company}`}
                  Columns={incentivesColumns(awardsDataSTI)}
                  Values={awardsDataSTI}
                  CompanyName={companyInfo?.Company}
                />
                <div style={{ margin: 10 }} />
                <hr />
                <h2 style={{ textAlign: "center" }}>Peer Group Comparison</h2>
                <p style={{ textAlign: "center" }}>
                  {/* Incentive Structure Comparison for {companyInfo.Company}'s Peer
            Group Coming Soon */}
                  Select a Comparison Group to compare to {companyInfo.Company}
                  's Incentive Structure:
                </p>
                <CohortSelector
                  heading={"Select Comparison Group:"}
                  companyInfo={companyInfo}
                  setCohortTableTickers={setCohortTableTickers}
                  cohortTableSelector={cohortSelected}
                  setCohortTableSelector={setCohortSelected}
                  removeCustomCohort={true}
                  additionalOptions={["None"]}
                />
                {cohortTableTickers.length ? (
                  <div style={{ marginTop: 10 }}>
                    <IncentivesTable
                      Title={`Long-Term Incentives for ${companyInfo.Company}'s ${cohortSelected}`}
                      Columns={incentivesColumns(allAwardsDataAllCompanies.LTI)}
                      Values={allAwardsDataAllCompanies.LTI}
                      CompanyName={companyInfo?.Company}
                      isPeerGroupTable={true}
                      peerGroupSelected={cohortSelected}
                      size={"small"}
                    />

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignContent: "baseline",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            Long-Term Incentives Prevalence Table
                          </h3>
                        </div>
                        <Table
                          dataSource={peerGroupMetrics["LTI"]}
                          columns={peerGroupMeasuresColumns}
                          style={{ marginTop: 5, width: "100%" }}
                          size={"small"}
                          pagination={{
                            hideOnSinglePage: true,
                            position: ["bottomCenter"],
                          }}
                          // onChange={(pagination) => setPagination(pagination.pageSize)}
                        />
                      </div>
                      <center>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            Long-Term Incentives Prevalence Chart
                          </h3>
                        </div>
                        <PieChart
                          width={700}
                          height={400}
                          style={{ minHeight: 400 }}
                        >
                          <Pie
                            data={filteredPeerGroupMeasures("LTI")}
                            dataKey="count"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            // label //default label
                            // labelLine={false}
                            isAnimationActive={false}
                            onMouseEnter={onPieEnter}
                            onMouseLeave={() => setActiveIndex("")}
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                          >
                            {filteredPeerGroupMeasures("LTI").map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    entry.label === "Other"
                                      ? "var(--gray-1)"
                                      : COLORS[index % COLORS.length]
                                  }
                                />
                              )
                            )}
                          </Pie>
                          {/* <RechartsToolTip /> */}
                        </PieChart>
                      </center>
                    </div>
                    <IncentivesTable
                      Title={`Short-Term Incentives for ${companyInfo.Company}'s ${cohortSelected}`}
                      Columns={incentivesColumns(allAwardsDataAllCompanies.STI)}
                      Values={allAwardsDataAllCompanies.STI}
                      CompanyName={companyInfo?.Company}
                      peerGroupSelected={cohortSelected}
                      size={"small"}
                    />
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        Short-Term Incentives Prevalence
                      </h3>
                    </div>

                    {/* <ResponsiveContainer key={pagination}> */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: 10,
                        marginBottom: 10,
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignContent: "baseline",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            Short-Term Incentives Prevalence Table
                          </h3>
                        </div>
                        <Table
                          dataSource={peerGroupMetrics["STI"]}
                          columns={peerGroupMeasuresColumns}
                          style={{ marginTop: 5, width: "100%" }}
                          size={"small"}
                          pagination={{
                            hideOnSinglePage: true,
                            position: ["bottomCenter"],
                          }}
                          // onChange={(pagination) => setPagination(pagination.pageSize)}
                        />
                      </div>
                      <center>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            Short-Term Incentives Prevalence Chart
                          </h3>
                        </div>
                        <PieChart
                          width={700}
                          height={400}
                          style={{ minHeight: 400 }}
                        >
                          <Pie
                            data={filteredPeerGroupMeasures("STI")}
                            dataKey="count"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            // label //default label
                            // labelLine={false}
                            isAnimationActive={false}
                            onMouseEnter={onPieEnter}
                            onMouseLeave={() => setActiveIndex("")}
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                          >
                            {filteredPeerGroupMeasures("STI").map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    entry.label === "Other"
                                      ? "var(--gray-1)"
                                      : COLORS[index % COLORS.length]
                                  }
                                />
                              )
                            )}
                          </Pie>
                          {/* <RechartsToolTip /> */}
                        </PieChart>
                      </center>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
