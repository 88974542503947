import { all } from "redux-saga/effects";
import * as UserSaga from "./UserSaga";
import * as PlansSaga from "./PlansSaga";
import * as CustomerSaga from "./CustomerSaga";
import * as CompanySaga from "./CompanySaga";
import { compose } from "redux";

export function* rootSaga() {
  yield all([
    UserSaga.watchingGetUserIP(),
    UserSaga.watchingCreateNewUserIP(),
    UserSaga.watchingCheckTableUseLimit(),
    UserSaga.watchingSetTableUseLimit(),

    UserSaga.watchingSignUp(),
    UserSaga.watchingSignIn(),
    UserSaga.watchingLogIn(),

    UserSaga.watchingGetUserInfo(),
    UserSaga.watchingUpdateUserDetails(),
    UserSaga.watchingUpdateUserPassword(),

    UserSaga.watchingResetPasswordRequest(),
    UserSaga.watchingVerifySecretCode(),
    UserSaga.watchingResetNewPassword(),
    UserSaga.watchingResendActivationLink(),

    PlansSaga.watchingGetAllPlans(),

    CustomerSaga.watchingGetSubscription(),
    CustomerSaga.watchingGetPaymentMethod(),
    CustomerSaga.watchingCancelSubscription(),
    CustomerSaga.watchingRenewSubscription(),
    CustomerSaga.watchingUpdatePaymentPlan(),

    CompanySaga.watchingGetAllCompanies(),
    CompanySaga.watchingGetAllMarketCap(),
    CompanySaga.watchingGetAllExecutives(),
    CompanySaga.watchingGetMarketCap(),
    CompanySaga.watchingGetParagraphsFrom8k502(),
    CompanySaga.watchingGetMonthlyReturns(),
    CompanySaga.watchingGetWeeklyReturns(),
    CompanySaga.watchingGetDailyPrices(),
    CompanySaga.watchingGetTreasuryYields(),
    CompanySaga.watchingGetAwards(),
    CompanySaga.watchingGetRiskFactors(),
    CompanySaga.watchingGetPlanBasedAwards(),
    CompanySaga.watchingGetCompensationDecisions(),
    CompanySaga.watchingGetIndexMonthlyRetruns(),
    CompanySaga.watchingGetMonthlyMarketcaps(),
    CompanySaga.watchingGetBoardPay(),
    CompanySaga.watchingGetAllBoardPay(),
    CompanySaga.watchingGetBoardPayProfiles(),
    CompanySaga.watchingGetExecutiveProfiles(),
    CompanySaga.watchingGetBoardPayStructure(),
    CompanySaga.watchingGetMedianByYear(),
    CompanySaga.watchingDisclosureSearch(),
    CompanySaga.watchingClearDisclosureResults(),
    CompanySaga.watchingCompAISearch(),
    CompanySaga.watchingClearCompAIResults(),
    CompanySaga.watchingCompAIAvailability(),
    CompanySaga.watchingCompAIResultRating(),
    CompanySaga.watchingRunHorseRace(),
    CompanySaga.watchingRunOptimisticHorseRace(),
    CompanySaga.watchingClearHorseRaceResults(),
  ]);
}
