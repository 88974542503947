import React, { useState, useEffect } from "react";
import { getExecutiveProfiles } from "../../redux/actions/CompanyAction";
import { useSelector, useDispatch } from "react-redux";
import slugifyName from "../../utils/slugifyName";

export default function ExecutiveProfile({
  companyInfo,
  execPosition,
  executiveSlug,
}) {
  const dispatch = useDispatch();

  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { executiveProfiles } = useSelector((state) => state.CompanyReducer);

  const [loading, setLoading] = useState(true);
  const [ticker, setTicker] = useState("");
  const [executiveProfile, setExecutiveProfile] = useState();

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getExecutiveProfiles(ticker, token));
      setLoading(false);
    }
  }, [companyInfo, dispatch]);

  const [executiveNotFound, setExecutiveNotFound] = useState(false);
  useEffect(() => {
    const matchingExecutiveProfile = executiveProfiles?.Executives?.find(
      (executive) => {
        return slugifyName(executive.Name) === executiveSlug;
      }
    );

    if (matchingExecutiveProfile) {
      setExecutiveNotFound(false);
      setExecutiveProfile(matchingExecutiveProfile);
    } else {
      setExecutiveNotFound(true);
    }
  }, [
    companies,
    companyInfo,
    executiveSlug,
    executiveProfiles,
    ticker,
    dispatch,
  ]);

  return (
    <div>
      {executiveNotFound || !executiveProfile ? (
        <div></div>
      ) : (
        <div
          className="mb-4"
          style={{
            outline: "1px solid lightgray",
            padding: 5,
            paddingTop: 10,
            margin: 5,
          }}
        >
          <div className="col-lg-12">
            <div className="container row member-profile">
              <h2
                style={{
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                {executiveProfile?.Type ? `${executiveProfile?.Type} ` : ""}
                Executive
                Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </h2>
              <div className="col-6">
                <div className="profile-header">
                  <h2>{executiveProfile?.Name}</h2>

                  <p style={{ marginBottom: "0.1em" }}>
                    <strong>
                      {execPosition.includes(", ") ? "Positions:" : "Position:"}
                    </strong>{" "}
                    {execPosition || "N/A"}
                  </p>
                  <p style={{ marginBottom: "0.1em" }}>
                    <strong>Age:</strong> {executiveProfile.Age || "N/A"}
                  </p>
                  <p style={{ marginBottom: "0.1em" }}>
                    <strong>Gender:</strong> {executiveProfile.Gender || "N/A"}
                  </p>
                  {/* <p style={{}}>
                  <strong>Member Since:</strong>{" "}
                  {executiveProfile.MemberSince || "N/A"}
                </p> */}
                </div>

                <div className="profile-section">
                  {executiveProfile.Education &&
                  executiveProfile.Education.length ? (
                    <>
                      <h2>Education</h2>
                      <ul>
                        {executiveProfile.Education.map((edu, index) => (
                          <li key={index}>
                            {edu.Degree ? (
                              <span>
                                <strong>{edu.Degree}</strong>,{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {edu?.Major ? (
                              <span>
                                <i>{edu?.Major}</i>,{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {edu.UniversityName}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="col-6 profile-section">
                {executiveProfile.Qualifications &&
                executiveProfile.Qualifications.length ? (
                  <>
                    <h2>Qualifications</h2>
                    <ul>
                      {executiveProfile.Qualifications.map(
                        (qualification, index) => (
                          <li key={index}>{qualification}</li>
                        )
                      )}
                    </ul>
                  </>
                ) : null}
              </div>
              <div className="col-12 profile-section">
                <h2>Profile</h2>
                {executiveProfile.Profile && executiveProfile.Profile.length ? (
                  <>
                    {executiveProfile.Profile.map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </>
                ) : (
                  <p>
                    Profile info is not available for {executiveProfile.Name}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* <div className="profile-section">
            <h2>Committees</h2>
            {executiveProfile.Committees &&
            executiveProfile.Committees.length ? (
              <ul>
                {executiveProfile.Committees.map((committee, index) => (
                  <li key={index}>{committee}</li>
                ))}
              </ul>
            ) : (
              <p>Committee data was not found for {executiveProfile.Name}</p>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
}
