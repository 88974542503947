import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "antd";
import { getPlanBasedAwards } from "../redux/actions/CompanyAction";
import { emdash } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";

export default function PlanBasedAwards({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  function addSpaceBetweenCapitalizedWords(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  const { planBasedAwards } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getPlanBasedAwards([ticker], token));
    }
  }, [companyInfo, dispatch]);

  const planBasedAwardsColumns = useMemo(() => {
    if (!planBasedAwards?.[companyInfo?.Ticker]?.PlanBasedAwards) return [];
    const compensationColumns = [];
    planBasedAwards?.[companyInfo?.Ticker].PlanBasedAwards.forEach(
      (executiveData) =>
        Object.keys(executiveData).forEach((tableKey) => {
          if (
            !compensationColumns.some((item) => item.key === tableKey) &&
            tableKey !== "Awards"
          ) {
            compensationColumns.push({
              title: addSpaceBetweenCapitalizedWords(tableKey),
              dataIndex: tableKey,
              key: tableKey,
              align: "center",
            });
          }
          const awards = executiveData?.Awards || [];
          awards.forEach((award) => {
            Object.entries(award).forEach(([awardKey, awardVal]) => {
              if (!compensationColumns.some((item) => item.key === awardKey)) {
                compensationColumns.push({
                  title:
                    addSpaceBetweenCapitalizedWords(awardKey) +
                    ((!awardKey.includes("Date") ||
                      awardKey.includes("Award")) &&
                    !awardKey.includes("Type")
                      ? planBasedAwards?.[
                          companyInfo?.Ticker
                        ].PlanBasedAwards?.some((exec) =>
                          exec?.Awards?.some((award) =>
                            Object.entries(award).some(([key, val]) => {
                              return key === awardKey && val?.isDollarAmount;
                            })
                          )
                        )
                        ? planBasedAwards?.[
                            companyInfo?.Ticker
                          ].PlanBasedAwards?.some((exec) =>
                            exec?.Awards?.some((award) =>
                              Object.entries(award).some(([key, val]) => {
                                return key === awardKey && val?.isPricePerShare;
                              })
                            )
                          )
                          ? " ($/share)"
                          : " ($)"
                        : " (#)"
                      : ""),
                  dataIndex: awardKey,
                  key: awardKey,
                  align: "center",
                  isAward: true,
                });
              }
            });
          });
        })
    );
    return compensationColumns;
  }, [planBasedAwards, companyInfo]);

  const PlanBasedAwardsData = useMemo(() => {
    if (!planBasedAwards?.[companyInfo?.Ticker]?.PlanBasedAwards) {
      return [];
    }

    const allData = [];
    planBasedAwards?.[companyInfo?.Ticker].PlanBasedAwards.forEach(
      (execData) => {
        execData?.Awards.forEach((award) => {
          const newRow = {};

          planBasedAwardsColumns.forEach((key) => {
            if (key?.isAward) {
              newRow[key.dataIndex] =
                typeof award?.[key.dataIndex] === "string" ? (
                  award?.[key.dataIndex]
                ) : award?.[key.dataIndex] &&
                  award?.[key.dataIndex].Value !== undefined ? (
                  award?.[key.dataIndex].Value.toLocaleString()
                ) : award?.[key.dataIndex] &&
                  award?.[key.dataIndex].Target !== undefined ? (
                  <div>
                    <p>
                      Threshold:&nbsp;
                      {award?.[key.dataIndex]?.Threshold !== undefined
                        ? award?.[key.dataIndex]?.Threshold.toLocaleString()
                        : emdash}
                    </p>
                    <p>
                      Target:&nbsp;
                      {award?.[key.dataIndex].Target.toLocaleString()}
                    </p>
                    <p>
                      Maximum:&nbsp;
                      {award?.[key.dataIndex]?.Maximum !== undefined
                        ? award?.[key.dataIndex]?.Maximum.toLocaleString()
                        : emdash}
                    </p>
                  </div>
                ) : (
                  emdash
                );
            } else {
              newRow[key.dataIndex] = execData?.[key.dataIndex]?.Value
                ? execData?.[key.dataIndex].Value?.toLocaleString()
                : planBasedAwards?.[companyInfo?.Ticker].PlanBasedAwards.some(
                    (otherExec) => {
                      return otherExec?.[key.dataIndex]?.Value;
                    }
                  )
                ? emdash
                : execData?.[key.dataIndex]
                ? execData?.[key.dataIndex]
                : emdash;
            }
          });
          allData.push(newRow);
        });
      }
    );
    return allData;
  }, [planBasedAwardsColumns, planBasedAwards, companyInfo]);

  // CSV export
  const formatDataForCSVexport = useCallback(() => {
    const planBasedAwardOutputData = [];
    if (PlanBasedAwardsData?.length) {
      planBasedAwardOutputData.push(
        ...PlanBasedAwardsData?.map((item) => {
          return { ...item };
        })
      );
    }
    const compensationDecisionHeaders = [];
    planBasedAwardsColumns.forEach((item) => {
      if (!compensationDecisionHeaders.includes(item.dataIndex)) {
        compensationDecisionHeaders.push(item.dataIndex);
      }
    });

    const allDataForCSV = [];

    planBasedAwardOutputData.forEach((item) => {
      const formattedItem = {};
      planBasedAwardsColumns.forEach((keyDetails, index) => {
        if (keyDetails?.dataIndex) {
          if (
            planBasedAwardOutputData.some(
              (data) => typeof data?.[keyDetails.dataIndex] === "object"
            )
          ) {
            const props = item?.[keyDetails.dataIndex]?.props?.children;

            if (props && props?.length >= 1) {
              for (const prop of props) {
                const propDetail = prop?.props?.children;
                if (propDetail && propDetail?.length >= 1) {
                  formattedItem[
                    `"${keyDetails.title.replaceAll(
                      "#",
                      "Number of Shares"
                    )} - "${propDetail[0]?.replace(":", "")}`
                  ] = propDetail[1].replaceAll(",", "");
                }
              }
            } else {
              const otherItem = planBasedAwardOutputData.find(
                (data) =>
                  typeof data?.[keyDetails.dataIndex] === "object" &&
                  data?.[keyDetails.dataIndex]?.props?.children
              );
              const otherProps =
                otherItem?.[keyDetails.dataIndex]?.props?.children;
              for (const prop of otherProps) {
                const propDetail = prop?.props?.children;
                if (propDetail && propDetail?.length >= 1) {
                  formattedItem[
                    `"${keyDetails.title.replaceAll(
                      "#",
                      "Number of Shares"
                    )} - "${propDetail[0]?.replace(":", "")}`
                  ] = "";
                }
              }
            }
          } else {
            formattedItem[
              index === 0
                ? `"${companyInfo?.Company} Executive Name"`
                : `"${keyDetails.title.replaceAll("#", "Number of Shares")}"`
            ] =
              item?.[keyDetails.dataIndex] &&
              (item?.[keyDetails?.dataIndex].length > 1 ||
                item?.[keyDetails?.dataIndex] >= 0)
                ? item[keyDetails?.dataIndex].replaceAll(",", "")
                : "";
          }
        }
      });
      allDataForCSV.push(formattedItem);
    });
    return allDataForCSV;
  }, [companyInfo, PlanBasedAwardsData, planBasedAwardsColumns]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access Grants of Plan-Based
              Awards.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            {!planBasedAwardsColumns.length ? (
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Grants of Plan-Based Awards for {companyInfo?.Ticker} Coming
                Soon
              </h2>
            ) : (
              <div>
                <h2>Grants of Plan-Based Awards</h2>
                {!PDFprocessing && (
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        exportToCSV(
                          formatDataForCSVexport(),
                          `${companyInfo?.Ticker} ${
                            planBasedAwards?.[companyInfo?.Ticker]
                              ?.ForwardLookingPerformanceYear || ""
                          } Grants of Plan-Based Awards ${
                            new Date().toString().split("GMT-")[0]
                          }.csv`
                        )
                      }
                    >
                      Export Grants of Plan-Based Awards as CSV
                    </Button>
                  </div>
                )}
                <Table
                  columns={planBasedAwardsColumns}
                  dataSource={PlanBasedAwardsData}
                  pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                />
                {planBasedAwards?.[companyInfo?.Ticker]?.AdditionalDetails && (
                  <p style={{ marginTop: 10 }}>
                    <b>Note: </b>

                    {planBasedAwards?.[companyInfo?.Ticker]?.AdditionalDetails}
                  </p>
                )}
                {planBasedAwards?.[companyInfo?.Ticker]?.Citation && (
                  <p style={{ marginTop: 10 }}>
                    <b>Citation: </b>

                    {planBasedAwards?.[companyInfo?.Ticker]?.Citation}
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
