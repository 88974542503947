import "../css/board-member.css";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import slugifyName from "../utils/slugifyName";
import PuffLoader from "react-spinners/PuffLoader";
import NotFound from "./notFound";
import { Link } from "react-router-dom";
import { ETRADE_URL, toDollar } from "../utils/Constants/systemSetting";
import {
  getBoardPay,
  getBoardPayProfiles,
} from "../redux/actions/CompanyAction";
import {
  formatMeasure,
  payRatioCalculatedMessage,
} from "../utils/Constants/systemSetting";
import ExportPDFButton from "../components/ExportPDFButton";

export default function BoardMemberOverview(props) {
  const dispatch = useDispatch();
  const { companies, marketCap: marketCaps } = useSelector(
    (state) => state.CompaniesReducer
  );
  const { boardPay } = useSelector((state) => state.CompanyReducer);
  const { boardPayProfiles } = useSelector((state) => state.CompanyReducer);

  const [loading, setLoading] = useState(true);
  const [year, setYearSelected] = useState("2023");
  const [ticker, setTicker] = useState("");
  const [boardMemberSlug, setBoardMemberSlug] = useState("");
  const [companyInfo, setCompanyInfo] = useState({});
  const [boardMember, setBoardMember] = useState();
  const [marketCap, setMarketCap] = useState();
  const [PDFprocessing, setPDFprocessing] = useState(false);
  const [payRatioIsCalculated, setPayRatioIsCalculated] = useState(false);

  const getDatapoint = useCallback(
    (datapoint) => {
      /* Allows for getting most recent year */
      if (
        companyInfo?.[datapoint]?.[year?.toString()]?.value ||
        companyInfo?.[datapoint]?.[year?.toString()]?.value === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: companyInfo?.[datapoint]?.[year?.toString()]?.value,
          calculated: companyInfo?.[datapoint]?.[year?.toString()]?.calculated
            ? true
            : false,
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [companyInfo, year]
  );

  const getMeasure = useCallback(
    (measureName) => {
      /* Allows for getting most recent year */
      if (
        companyInfo?.Measures?.[measureName]?.[year?.toString()] ||
        companyInfo?.Measures?.[measureName]?.[year?.toString()] === 0 // revenue can be 0
      ) {
        return {
          year: year,
          value: companyInfo?.Measures?.[measureName]?.[year?.toString()],
        };
      }
      // }
      return { year: "", value: "N/A" };
    },
    [companyInfo, year]
  );
  const renderNetIncome = (netIncome) => {
    if (netIncome === "N/A") {
      return <span>N/A</span>;
    } else if (netIncome < 0) {
      return (
        <span className="text-danger">
          ({toDollar(Number(netIncome.toString().slice(1)))})
        </span>
      );
    } else {
      return <span className="text-success">{toDollar(netIncome)}</span>;
    }
  };

  useEffect(() => {
    setPayRatioIsCalculated(getDatapoint("PayRatio")?.calculated);
  }, [getDatapoint, companyInfo, year]);

  const elementsIDsForPDFExport = useMemo(() => {
    const elementsIDs = [
      ["executive-overview", "percentile-table"],
      ["cohort-selectors-and-table"],
      ["cohort-summary-section", "measure-correlation-graph"],
    ];
    return elementsIDs;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getBoardPay(ticker, token));
      dispatch(getBoardPayProfiles(ticker, token));
      setLoading(false);
    }
  }, [companyInfo, dispatch]);

  const handleYearChange = (e) => {
    setYearSelected(e.target.value);
  };
  const yearsAvailable = useMemo(() => {
    if (!boardPay?.BoardPay) return [];
    setYearSelected("");
    return Object.keys(boardPay?.BoardPay)
      .map((item, index) => ({ value: item, label: item, key: index }))
      .sort((a, b) => parseInt(b.value) - parseInt(a.value));
  }, [boardPay]);

  useEffect(() => {
    if (yearsAvailable && yearsAvailable.length && yearsAvailable?.[0]?.value) {
      setYearSelected("2023");
    }
  }, [yearsAvailable]);

  const yearsOptions = useMemo(() => {
    return yearsAvailable
      .map((item) => item.value)
      .map((year, index) => (
        <option value={year} key={index}>
          {year}
        </option>
      ));
  }, [yearsAvailable]);

  const [boardMemberNotFound, setBoardMemberNotFound] = useState(false);
  useEffect(() => {
    setTicker(props.match.params?.id);
    setBoardMemberSlug(props.match.params?.boardMemberName);

    const urlParts = props.location.pathname.split("/");
    if (!urlParts.includes(boardMemberSlug)) {
      setLoading(true);
    }
    setCompanyInfo(companies[ticker]);

    const matchingCompany = marketCaps.find(
      (company) => company.Ticker === ticker
    );
    if (matchingCompany) {
      setMarketCap(matchingCompany.marketCap);
    }

    const matchingBoardMember = boardPayProfiles?.BoardMembers?.find(
      (boardMember) => {
        return slugifyName(boardMember.Name) === boardMemberSlug;
      }
    );

    if (matchingBoardMember) {
      setBoardMemberNotFound(false);
      setBoardMember(matchingBoardMember);
    } else {
      setBoardMemberNotFound(true);
    }
  }, [
    props,
    companies,
    marketCaps,
    companyInfo,
    boardMemberSlug,
    boardPayProfiles,
    ticker,
    dispatch,
  ]);

  return (
    <div
      className="overview container mb-3"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {loading ? (
        <center
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PuffLoader color={"var(--loader)"} loading={loading} size={100} />
        </center>
      ) : boardMemberNotFound ? (
        <NotFound />
      ) : (
        <div className="container" style={{ marginTop: "125px" }}>
          <h1 style={{ marginBottom: 0, textAlign: "center" }}>
            Board Member Profile
          </h1>
          <h1 style={{ textAlign: "center" }}>{boardMember?.Name}</h1>

          <div
            className="row"
            id="executive-overview"
            style={{ marginTop: 50 }}
          >
            <div className="col-lg-6 mb-4 company-info">
              <h4 style={{ color: "var(--link-blue)" }}>
                <Link to={`/company/${companyInfo?.Ticker}#board`}>
                  {companyInfo?.Company}
                </Link>
              </h4>
              <div>
                Year:{" "}
                {!PDFprocessing ? (
                  <select
                    name="year"
                    onChange={handleYearChange}
                    value={year}
                    style={{ textAlign: "center", height: 30 }}
                  >
                    {yearsOptions}
                  </select>
                ) : (
                  <span>{year}</span>
                )}
              </div>
              <div>
                Stock Ticker:{" "}
                <a
                  target="_blank"
                  href={`${ETRADE_URL}${companyInfo?.Ticker}`}
                  rel="noreferrer"
                >
                  {companyInfo?.Ticker}
                </a>
              </div>
              {boardMember?.AlternateTickers?.length ? (
                <div>
                  Alternate Ticker
                  {boardMember?.AlternateTickers?.length > 2 && "s"}:{" "}
                  <span>
                    {boardMember?.AlternateTickers.filter(
                      (ticker) => ticker !== boardMember?.Ticker
                    ).join(", ")}
                  </span>
                </div>
              ) : null}
              <div>
                Sector: <span>{companyInfo?.Sector || "Other"}</span>
              </div>
              <div>
                Industry: <span>{companyInfo?.Industry || "Other"}</span>
              </div>
              <div>
                {year} Assets:{" "}
                <span>
                  {getMeasure("Assets")?.value !== "N/A"
                    ? "$" + getMeasure("Assets")?.value?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                {year} Revenue:{"  "}
                <span>
                  {getDatapoint("Revenue")?.value !== "N/A"
                    ? "$" + getDatapoint("Revenue")?.value?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                {year} Profit (Loss):{" "}
                {renderNetIncome(getDatapoint("NetIncome")?.value)}
              </div>
              <div>
                {year} CEO Pay Ratio:{" "}
                {getDatapoint("PayRatio")?.value !== "N/A" ? (
                  <span>
                    {formatMeasure(getDatapoint("PayRatio").value, "ratio")} : 1
                    {payRatioIsCalculated && <sup> †</sup>}
                  </span>
                ) : (
                  <span>N/A</span>
                )}
              </div>
              <div>
                Current Market Cap:{" "}
                <span>
                  {marketCap
                    ? "$" + parseInt(marketCap).toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                Current Employee Count:{" "}
                <span>
                  {companyInfo?.Employees >= 0
                    ? companyInfo?.Employees?.toLocaleString()
                    : "N/A"}
                </span>
              </div>
              <div>
                Location: <span>{companyInfo?.Address || "N/A"}</span>
              </div>

              <div style={{ marginTop: 6 }}>
                <span>
                  {payRatioIsCalculated &&
                    payRatioCalculatedMessage(companyInfo, year)}
                </span>
              </div>
              <div className="exportPDF" style={{ marginTop: 6 }}>
                <ExportPDFButton
                  setPDFprocessing={setPDFprocessing}
                  elementIDgroups={elementsIDsForPDFExport}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 ">
              <div className="container member-profile">
                <div className="profile-header">
                  {/* <h1>{boardMember.Name}</h1> */}

                  <h3 style={{ marginBottom: 10 }}>
                    {boardMember?.Type
                      ? `${boardMember?.Type}${
                          boardMember?.TypeNotes ? "*" : ""
                        } `
                      : ""}
                    Board Member{boardMember?.LeadDirector ? ", " : ""}
                  </h3>
                  {boardMember?.LeadDirector && (
                    <h3 style={{ marginBottom: 10 }}>Lead Director</h3>
                  )}

                  <p style={{ marginBottom: "0.1em" }}>
                    <strong>Age:</strong> {boardMember.Age || "N/A"}
                  </p>
                  <p style={{ marginBottom: "0.1em" }}>
                    <strong>Gender:</strong> {boardMember.Gender || "N/A"}
                  </p>
                  <p style={{}}>
                    <strong>Member Since:</strong>{" "}
                    {boardMember.MemberSince || "N/A"}
                  </p>
                  {boardMember?.TypeNotes && (
                    <p style={{ marginTop: "0.5em", fontSize: 14 }}>
                      * {boardMember?.TypeNotes}
                    </p>
                  )}
                </div>

                <div className="profile-section">
                  {boardMember.Education && boardMember.Education.length ? (
                    <>
                      <h2>Education</h2>
                      <ul>
                        {boardMember.Education.map((edu, index) => (
                          <li key={index}>
                            <strong>{edu.Degree}</strong>,{" "}
                            {edu?.Major ? (
                              <span>
                                <i>{edu?.Major}</i>,{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {edu.UniversityName}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </div>

                <div className="profile-section">
                  {boardMember.Qualifications &&
                  boardMember.Qualifications.length ? (
                    <>
                      <h2>Qualifications</h2>
                      <ul>
                        {boardMember.Qualifications.map(
                          (qualification, index) => (
                            <li key={index}>{qualification}</li>
                          )
                        )}
                      </ul>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="profile-section">
              <h2>Profile</h2>
              {boardMember.Profile && boardMember.Profile.length ? (
                <>
                  {boardMember.Profile.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
                </>
              ) : (
                <p>Profile info is not available for {boardMember.Name}</p>
              )}
            </div>

            <div className="profile-section">
              <h2>Committees</h2>
              {boardMember.Committees && boardMember.Committees.length ? (
                <ul>
                  {boardMember.Committees.map((committee, index) => (
                    <li key={index}>{committee}</li>
                  ))}
                </ul>
              ) : (
                <p>Committee data was not found for {boardMember.Name}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
