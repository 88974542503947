import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "antd";
import { getCompensationDecisions } from "../redux/actions/CompanyAction";
import { emdash } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";

export default function CompensationDecisions({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const { compensationDecisions } = useSelector(
    (state) => state.CompanyReducer
  );

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getCompensationDecisions([ticker], token));
    }
  }, [companyInfo, dispatch]);

  function addSpaceBetweenCapitalizedWords(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  // const reformattedCompensationDecisions = useMemo(() => {
  //   const newCompensationDecisionData = Object.entries(executiveData).map(([tableKey, tableValue]) => {
  //     if (tableKey === "DirectTotal" && ! Object.keys(executiveData).some((item) => item === "Total")) {
  //       tableKey = "Total"
  //     }
  //     return newCompensationDecisionData
  // },[compensationDecisionData])

  const compensationDecisionColumns = useMemo(() => {
    const tableKeyTitleMap = {
      PSUs: "PSUs",
      RSUs: "RSUs",
      LTIs: "LTIs",
      ExecutiveName: "Executive Name",
      OptionAwards: "Option Awards",
    };
    if (!compensationDecisions?.[companyInfo?.Ticker]?.ForwardDisclosedTable)
      return [];
    const compensationColumns = [];
    compensationDecisions?.[companyInfo?.Ticker].ForwardDisclosedTable.forEach(
      (executiveData) => {
        if (
          !(executiveData.Total && executiveData.DirectTotal) &&
          (executiveData.Total || executiveData.DirectTotal)
        ) {
          executiveData.Total =
            executiveData.Total !== undefined
              ? executiveData.Total
              : executiveData.DirectTotal;
          delete executiveData.DirectTotal;
        }

        // return executiveData;
        Object.entries(executiveData).forEach(([tableKey, tableValue]) => {
          if (!compensationColumns.some((item) => item.key === tableKey)) {
            compensationColumns.push({
              title:
                (tableKeyTitleMap?.[tableKey] ||
                  toTitleCase(addSpaceBetweenCapitalizedWords(tableKey))) +
                (tableKey !== "ExecutiveName"
                  ? tableValue?.isDollarAmount
                    ? " ($)"
                    : " (#)"
                  : ""),
              dataIndex: tableKey,
              key: tableKey,
              align: "center",
            });
          }
        });
      }
    );
    const sortedColumns = compensationColumns.sort((a, b) => {
      if (a.dataIndex === "Total") return 1;
      if (b.dataIndex === "Total") return -1;
      return 0;
    });
    return sortedColumns;
  }, [compensationDecisions, companyInfo]);

  const compensationDecisionData = useMemo(() => {
    if (!compensationDecisions?.[companyInfo?.Ticker]?.ForwardDisclosedTable) {
      return [];
    }

    return compensationDecisions?.[
      companyInfo?.Ticker
    ].ForwardDisclosedTable.map((execData) => {
      const newRow = {};

      compensationDecisionColumns.forEach((key) => {
        newRow[key.dataIndex] = execData?.[key.dataIndex]?.Value
          ? execData?.[key.dataIndex].Value?.toLocaleString()
          : compensationDecisions?.[
              companyInfo?.Ticker
            ].ForwardDisclosedTable.some((otherExec) => {
              return otherExec?.[key.dataIndex]?.Value;
            })
          ? emdash
          : execData?.[key.dataIndex]
          ? execData?.[key.dataIndex]
          : emdash;
      });
      return newRow;
    });
  }, [compensationDecisionColumns, compensationDecisions, companyInfo]);

  // CSV export
  const formatDataForCSVexport = useCallback(() => {
    const compensationDecisionOutputData = [];
    if (compensationDecisionData?.length) {
      compensationDecisionOutputData.push(
        ...compensationDecisionData?.map((item) => {
          return { ...item };
        })
      );
    }
    const compensationDecisionHeaders = [];
    compensationDecisionColumns.forEach((item) => {
      if (!compensationDecisionHeaders.includes(item.dataIndex)) {
        compensationDecisionHeaders.push(item.dataIndex);
      }
    });
    const formattedBoardPayData = compensationDecisionOutputData.map((item) => {
      const formattedItem = {};
      compensationDecisionColumns.forEach((keyDetails, index) => {
        if (keyDetails?.dataIndex) {
          formattedItem[
            index === 0
              ? `"${companyInfo?.Company} Executive Name"`
              : `"${keyDetails.title.replaceAll("#", "Number of Shares")}"`
          ] =
            item?.[keyDetails.dataIndex] &&
            (item?.[keyDetails?.dataIndex].length > 2 ||
              item?.[keyDetails?.dataIndex] >= 0)
              ? item[keyDetails?.dataIndex].replaceAll(",", "")
              : "";
        }
      });
      return formattedItem;
    });
    return formattedBoardPayData;
  }, [companyInfo, compensationDecisionData, compensationDecisionColumns]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access Compensation Decisions.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            {!compensationDecisionColumns.length &&
            !compensationDecisions?.[companyInfo?.Ticker]
              ?.ForwardLookingPerformanceYear ? (
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Compensation Decisions for {companyInfo?.Ticker} Coming Soon
              </h2>
            ) : !compensationDecisionColumns.length &&
              compensationDecisions?.[companyInfo?.Ticker]
                ?.ForwardLookingPerformanceYear ? (
              <div>
                {" "}
                <h2>
                  {compensationDecisions?.[companyInfo?.Ticker]
                    ?.ForwardLookingPerformanceYear
                    ? `${
                        compensationDecisions?.[companyInfo?.Ticker]
                          ?.ForwardLookingPerformanceYear
                      } `
                    : ""}
                  Compensation Decisions
                </h2>
                <p style={{ fontSize: "1.5em", textAlign: "center" }}>
                  No new compensation decisions for {companyInfo?.Company} in{" "}
                  {
                    compensationDecisions?.[companyInfo?.Ticker]
                      ?.ForwardLookingPerformanceYear
                  }
                </p>
                {/* {compensationDecisions?.[companyInfo?.Ticker]
                  ?.ExplanationOfFailure && (
                  <p style={{ marginTop: 10 }}>
                    <b>Reason: </b>
                    {
                      compensationDecisions?.[companyInfo?.Ticker]
                        ?.ExplanationOfFailure
                    }
                  </p>
                )} */}
              </div>
            ) : (
              <div>
                <h2>
                  {compensationDecisions?.[companyInfo?.Ticker]
                    ?.ForwardLookingPerformanceYear
                    ? `${
                        compensationDecisions?.[companyInfo?.Ticker]
                          ?.ForwardLookingPerformanceYear
                      } `
                    : ""}
                  Compensation Decisions
                </h2>
                {!PDFprocessing && (
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        exportToCSV(
                          formatDataForCSVexport(),
                          `${companyInfo?.Ticker} ${
                            compensationDecisions?.[companyInfo?.Ticker]
                              ?.ForwardLookingPerformanceYear || ""
                          } Compensation Decisions ${
                            new Date().toString().split("GMT-")[0]
                          }.csv`
                        )
                      }
                    >
                      Export Compensation Decisions as CSV
                    </Button>
                  </div>
                )}
                <Table
                  columns={compensationDecisionColumns}
                  dataSource={compensationDecisionData}
                  pagination={{ hideOnSinglePage: true }}
                />
                {compensationDecisions?.[companyInfo?.Ticker]
                  ?.AdditionalDetails && (
                  <p style={{ marginTop: 10 }}>
                    <b>Note: </b>

                    {
                      compensationDecisions?.[companyInfo?.Ticker]
                        ?.AdditionalDetails
                    }
                  </p>
                )}
                {compensationDecisions?.[companyInfo?.Ticker]?.Citation && (
                  <p style={{ marginTop: 10 }}>
                    <b>Citation: </b>

                    {compensationDecisions?.[companyInfo?.Ticker]?.Citation}
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
